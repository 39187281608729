<template>
	<div class="Login">
		<div class="login_bg">
			<div class="login_adv">
				<div class="login_adv_title">
					<h2>游戏管理系统</h2>
					<h4>高性能 / 精致 / 优雅</h4>
					<!-- <p>基于Vue3 + Element-Plus 的中后台前端解决方案。</p> -->
				</div>
				<div class="login_adv__bottom">© 1.4.0</div>
			</div>
			<div class="login_main">
				<div class="login_form">
					<div class="login_header">
						<!-- <div class="logo">
							<img src="../assets/logo.png" alt="" />
							<label>VUE3</label>
						</div> -->
						<h2>用户登录</h2>
					</div>
					<el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="demo-ruleForm">
						<el-form-item prop="userName">
							<el-input v-model="ruleForm.userName" prefix-icon="el-icon-user" clearable placeholder="请输入用户名"></el-input>
						</el-form-item>
						<el-form-item prop="passWord">
							<el-input v-model="ruleForm.passWord" prefix-icon="el-icon-lock" placeholder="请输入用密码" clearable show-password />
						</el-form-item>
						<el-form-item style="margin-bottom: 10px;">
							<div class="forget">
								<el-checkbox v-model="checked1" label="记住密码"></el-checkbox>
								<el-button type="primary" link>忘记密码？</el-button>
							</div>
						</el-form-item>
						<el-form-item><el-button type="primary" round style="width: 100%;" :loading="islogin" @click="loginForm()">登录</el-button></el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'login',
	data() {
		return {
			islogin: false,
			checked1: false,
			ruleForm: {
				userName: '',
				passWord: ''
			},
			rules: {
				userName: [
					{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}
				],
				passWord: [
					{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}
				]
			}
		};
	},
	created() {
		this.$TOOL.data.remove("TOKEN")
		this.$TOOL.data.remove("USER_INFO")
		this.$TOOL.data.remove("DATA_SELECTEED")
		this.$TOOL.data.remove("USERID")
		this.$store.commit("clearViewTags")
		this.$store.commit("clearKeepLive")
		// 回车登录
		let that = this;
		document.onkeypress = function(e) {
			var keycode = document.all ? event.keyCode : e.which;
			if (keycode == 13) {
				that.loginForm(); // 登录方法名
				return false;
			}
		};
		//页面加载调用获取cookie值
		this.getCookie();
	},
	methods: {
		async loginForm() {
			var validate = await this.$refs.ruleForm.validate().catch(() => {});
			if (!validate) {
				return false;
			}
			this.islogin = true;
			const that = this;
			this.$HTTP.post(
				'passport/signin',
				{
					username: that.ruleForm.userName,
					password: that.$TOOL.crypto.MD5(that.ruleForm.userName + '929123f8f17944e8b0a531045453e1f1' + that.ruleForm.passWord)
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							// console.log(response.data);
							//判断复选框是否被勾选 勾选则调用配置cookie方法
							if (that.checked1 == true) {
								//传入账号名，密码，和保存天数3个参数
								that.setCookie(that.ruleForm.userName, that.ruleForm.passWord, that.checked1, 7);
							} else {
								//清空Cookie
								that.clearCookie();
							}
							//存储token
							this.$TOOL.data.set("TOKEN", response.data.data.token)
							this.$TOOL.data.set("USER_INFO", response.data.data)
							
							that.$HTTP.updateHeader('Authorization', response.data.data.token);

							this.$message.success(response.data.msg);
							that.$router.replace({
								path: '/'
							});
						} else {
							this.$message.error(response.data.msg);
							this.islogin = false
						}
					} else {
						console.log(response.message);
					}
				}
			);
			
			this.islogin = false;
		},
		//设置cookie
		setCookie(c_name, c_pwd, c_bar, exdays) {
			var exdate = new Date(); //获取时间
			exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
			//字符串拼接cookie
			window.document.cookie = 'userNam' + '=' + c_name + ';path=/;expires=' + exdate.toGMTString();
			window.document.cookie = 'userPwd' + '=' + c_pwd + ';path=/;expires=' + exdate.toGMTString();
			window.document.cookie = 'berPass' + '=' + c_bar + ';path=/;expires=' + exdate.toGMTString();
		},
		//读取cookie
		getCookie: function() {
			if (document.cookie.length > 0) {
				var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
				for (var i = 0; i < arr.length; i++) {
					var arr2 = arr[i].split('='); //再次切割
					if (arr2[0] == 'userNam') {
						this.ruleForm.userName = arr2[1]; //保存到保存数据的地方
					} else if (arr2[0] == 'userPwd') {
						this.ruleForm.passWord = arr2[1];
					} else if (arr2[0] == 'berPass') {
						this.checked1 = JSON.parse(arr2[1]);
					}
				}
				
			}
		},
		//清除cookie
		clearCookie: function() {
			this.setCookie('', '', false, -1); //修改2值都为空，天数为负1天就好了
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.Login {
	height: 100%;
	.login_bg {
		width: 100%;
		height: 100%;
		background: #fff;
		display: flex;
		.login_adv {
			width: 33.33%;
			height: 100%;
			background-image: url(../assets/auth_banner.jpg);
			background-repeat: no-repeat;
			background-size: cover;
			background-position: 50%;
			position: relative;
			.login_adv_title {
				color: #fff;
				padding: 40px;
				h2 {
					font-size: 40px;
				}
				h4 {
					font-size: 18px;
					margin-top: 10px;
					font-weight: 400;
				}
				p {
					font-size: 14px;
					margin-top: 10px;
					color: hsla(0, 0%, 100%, 0.6);
					line-height: 1.8;
				}
			}
			.login_adv__bottom {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				color: #fff;
				padding: 40px;
				background-image: linear-gradient(transparent, #000);
			}
		}
		.login_main {
			flex: 1;
			display: flex;
			overflow: auto;
			.login_form {
				width: 400px;
				margin: auto;
				padding: 20px 0;
				.login_header {
					margin-bottom: 20px;
					.logo {
						display: flex;
						align-items: center;
						img {
							width: 35px;
							height: 35px;
							margin-right: 10px;
						}
						label {
							font-size: 24px;
						}
					}
					h2 {
						font-size: 24px;
						font-weight: 700;
						margin-top: 50px;
					}
				}
				.forget {
					display: flex;
					justify-content: space-between;
				}
			}
		}
	}
}
</style>
